export default {
  colors: {
    background: '#fff',
    primaryGreen: '#2BBD7E',
    secondaryGreen: '#BFEBD8',
    lightGreen: '#69F0AE',
    darkGreen: '#219653',
    darkerGreen: '#0d3926',
    tableDarkRow: '#01170E',
    tableLightRow: '#06281A',
    brown: '#F2994A',
    red: {
      'shade-1': '#EB5757',
    },
    blue: {
      'shade-1': '#2F80ED',
      'shade-2': '#05d5df',
      'shade-3': '#BBE4DD',
    },
    dark: {
      'shade-1': '#000000',
      'shade-2': '#01170E',
      'shade-3': '#06281A',
    },
    light: {
      'shade-1': '#ffffff',
      'shade-2': '#e5e5e5',
      'shade-3': '#5393de'
    },
  },
  font: {
    familyRegular: 'Montserrat',
    size: {
      smallest: '12px',
      smaller: '14px',
      smallLess: '16px',
      small: '18px',
      smallMedium: '20px',
      medium: '24px',
      mediumLarge: '32px',
      large: '46px',
    },
  },
  spacing: {
    large: 30,
    medium: 20,
    small: 15,
    tiny: 10,
  },
  screenSizes: {
    mobile: '(max-width: 425px)',
    tablet: '(max-width: 768px)',
    laptop: '(max-width: 1440px)',
    desktop: '(min-width: 1441px)',
  }
}