// libs
import React from 'react'
import Styled from 'styled-components'

// images
import logo from './../../assets/logo.png'
import profile from './../../assets/profile.jpeg'

// tech
import dotnet from './../../assets/langs/dotnetcore.png'
import angular from './../../assets/langs/angular.png'
import vue from './../../assets/langs/vue.png'
import react from './../../assets/langs/react.png'
import reactnative from './../../assets/langs/reactnative.png'
import electron from './../../assets/langs/electron.png'
import unity from './../../assets/langs/unity.png'

// services
import game from './../../assets/services/game.png'
import web from './../../assets/services/web.png'
import mobile from './../../assets/services/mobile.png'
import desktop from './../../assets/services/desktop.png'

// contact
import github from './../../assets/github.png'
import linkedin from './../../assets/linkedin.png'
import gmail from './../../assets/gmail.png'

interface Contact {
  icon: string
  href: string
  target: string
}

interface Service {
  title: string
  description: string
  icon: string
}

interface Technology {
  alt: string
  icon: string
}

const contacts: Array<Contact> = [
  {
    icon: github,
    href: 'https://github.com/dnovacik',
    target: '_blank'
  },
  {
    icon: linkedin,
    href: 'https://www.linkedin.com/in/novacikdaniel/',
    target: '_blank'
  },
  {
    icon: gmail,
    href: 'mailto:novacik.daniel@gmail.com',
    target: '_self'
  }
]

const services: Array<Service> = [
  {
    title: 'Game Development',
    description: 'Game development, both mobile and pc/desktop games using unity engine and using c# language',
    icon: game
  },
  {
    title: 'Web Development',
    description: 'Web development utilizing Angular, Vue, React on FrontEnd, with .net/.netcore/node on the backend, along with db, preferrably T-SQL for the .net stack or mongodb for the node environment',
    icon: web
  },
  {
    title: 'Mobile Development',
    description: 'Mobile app development utilizing React-Native with styled-components, usually paired with node environment on the backend',
    icon: mobile
  },
  {
    title: 'Desktop Development',
    description: 'Desktop development, using either the electronjs along with a JS FrontEnd framework, or using UWP/WPF solution in the .net realm',
    icon: desktop
  }
]

const technologies: Array<Technology> = [
  {
    alt: 'C#/.NET',
    icon: dotnet
  },
  {
    alt: 'Angular',
    icon: angular
  },
  {
    alt: 'React',
    icon: react
  },
  {
    alt: 'Vue',
    icon: vue
  },
  {
    alt: 'React-Native',
    icon: reactnative
  },
  {
    alt: 'Electron',
    icon: electron
  },
  {
    alt: 'Unity',
    icon: unity
  }
]

export default (): JSX.Element => {
  return (
    <Home.Layout>
      <Home.Logo src={logo} alt="CogniSion" />
      <Home.ContactsWrapper>
        {
          contacts.map((contact, index) => {
            return (
              <Home.Contact key={`contact-${index}`} href={contact.href} target={contact.target}>
                <Home.ContactIcon src={contact.icon} />
              </Home.Contact>
            )
          })
        }
      </Home.ContactsWrapper>
      <Home.Title>Freelance Software Development Services</Home.Title>
      <Home.Subtitle>in</Home.Subtitle>
      <Home.Subtitle>C#/.NET | JS/TS | Unity</Home.Subtitle>

      <Home.AboutWrapper>
        <Home.AvatarWrapper>
          <Home.Avatar src={profile} />
        </Home.AvatarWrapper>
        <Home.InfoWrapper>
        <Home.InfoRow>
            <Home.InfoLabel>Daniel Nováčik - CogniSion</Home.InfoLabel>
          </Home.InfoRow>
          <Home.InfoRow>
            <Home.InfoDescription>
              Senior fullstack software developer passionate about game, web, mobile and desktop development.
              Seasoned veteran especially when it comes to web development.
              Huge blockchain enthusiast and a gamer at heart.
            </Home.InfoDescription>
          </Home.InfoRow>
        </Home.InfoWrapper>
      </Home.AboutWrapper>

      <Home.ServicesWrapper>
        {
          services.map((service, index) => {
            return (
              <Home.ServiceWrapper key={`service-${index}`}>
                <Home.ServiceIcon src={service.icon} />
                <Home.ServiceTitle>{service.title}</Home.ServiceTitle>
                <Home.ServiceDescription>{service.description}</Home.ServiceDescription>
              </Home.ServiceWrapper>
            )
          })
        }
      </Home.ServicesWrapper>
      <Home.TechnologyWrapper>
        {
          technologies.map((technology, index) => {
            return (
              <Home.Technology key={`technology-${index}`} src={technology.icon} alt={technology.alt} />
            )
          })
        }
      </Home.TechnologyWrapper>
    </Home.Layout>
  )
}

const Home = {
  Layout: Styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 50px 0;
    align-items: center;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      padding: 0 20px;
    }
  `,
  Logo: Styled.img`
    display: flex;
    max-width: 40%;
    max-height: 40%;
    margin-bottom: 30px;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      max-width: 70%;
    }

    @media only screen and ${props => props.theme.screenSizes.mobile} {
      max-width: 80%;
    }
  `,
  ContactsWrapper: Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  ContactWrapper: Styled.div`
    display: flex;
  `,
  Contact: Styled.a`
    display: flex;
    margin: 0 15px;
    text-decoration: none;
    filter: grayscale(100%);

    &:visited, &:active {
      text-decoration: none;
    }

    &:hover {
      filter: grayscale(0%);
    }
  `,
  ContactIcon: Styled.img`
    display: flex;
    width: 20px;
    height: 20px;
  `,
  Title: Styled.h2`
    color: ${props => props.theme.colors.dark['shade-1']};
    margin-bottom: 0;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      font-size: ${props => props.theme.font.size.smallLess};
      text-align: center;
    }

    @media only screen and ${props => props.theme.screenSizes.mobile} {
      font-size: ${props => props.theme.font.size.small};
      text-align: center;
    }
  `,
  Subtitle: Styled.h3`
    margin: 0;
    color: ${props => props.theme.colors.dark['shade-1']};

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      font-size: ${props => props.theme.font.size.smallLess};
      text-align: center;
    }

    @media only screen and ${props => props.theme.screenSizes.mobile} {
      font-size: ${props => props.theme.font.size.small};
      text-align: center;
    }
  `,
  AboutWrapper: Styled.div`
    display: flex;
    margin: 70px 0;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      flex-direction: column;
    }
  `,
  AvatarWrapper: Styled.div`
    display: flex;
    width: 250px;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      width: 100%;
      justify-content: center;
    }
  `,
  Avatar: Styled.img`
    display: flex;
    border-radius: 50%;
    width: 200px;
    height: 200px;
  `,
  InfoWrapper: Styled.div`
    display: flex;
    margin: 20px 0;
    flex-direction: column;
    width: 500px;
    color: ${props => props.theme.colors.dark['shade-1']};

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      width: 100%;
      justify-content: center;
    }
  `,
  InfoRow: Styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      justify-content: center;
    }
  `,
  InfoLabel: Styled.span`
    display: flex;
    font-weight: 500;
  `,
  InfoData: Styled.span`
    display: flex;
    font-size: ${props => props.theme.font.size.smaller};
  `,
  InfoDescription: Styled.p`
    margin: 10px 0;
    font-size: ${props => props.theme.font.size.smaller};

    &::first-letter {
      font-size: ${props => props.theme.font.size.smallMedium};
      font-weight: 500;
    }
  `,
  ServicesWrapper: Styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    margin: 50px 0px;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      flex-wrap: wrap;
    }

    @media only screen and ${props => props.theme.screenSizes.mobile} {
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
  `,
  ServiceWrapper: Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      min-width: 40%;
      margin-bottom: 40px;
    }

    @media only screen and ${props => props.theme.screenSizes.mobile} {
      max-width: 100%;
      margin-bottom: 40px;
    }
  `,
  ServiceIcon: Styled.img`
    display: flex;
    width: 40px;
    height: 40px;
  `,
  ServiceTitle: Styled.h4`
    display: flex;
    color: ${props => props.theme.colors.dark['shade-1']};
  `,
  ServiceDescription: Styled.span`
    display: flex;
    color: ${props => props.theme.colors.dark['shade-1']};
    font-size: ${props => props.theme.font.size.smaller};
    text-align: center;
  `,
  TechnologyWrapper: Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    margin: 0 50px;
  `,
  Technology: Styled.img`
    height: 50px;
    margin: 0 25px;
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(0%);
    }

    @media only screen and ${props => props.theme.screenSizes.tablet} {
      height: 30px;
      margin: 0 10px;
    }

    @media only screen and ${props => props.theme.screenSizes.mobile} {
      height: 25px;
      margin: 0 5px;
    }
  `
}